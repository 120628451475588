import { apps } from '@lightning/configuration';

export const environment = {
    production: false,
    apps,
    gateway: {
        reconnectionDelay: 10000,
        getStateInterval: 2000,
        linesSeparator: '\n',
        serial: {
            devicesFilters: [{ usbVendorId: 0x1A86 }], // https://devicehunt.com/view/type/usb/vendor/1A86/device/7523
            options: {
                baudRate: 19200, // 115200
                dataBits: 8,
                parity: 'none',
                bufferSize: 512,
                flowControl: 'none'
            } as SerialOptions
        },
        usb: {
            devicesFilters: [{ vendorId: 0x1A86 }],
            options: {
                configurationNumber: 1,
                interfaceNumber: 0,
                alternateInterfaceNumber: 0,
                endpointNumber: 2,
            }
        },
        bluetooth: {
            serviceUuid: '4fafc201-1fb5-459e-8fcc-c5c9c331914b',
            characteristicInputUuid: 'beb5483e-36e1-4688-b7f5-ea07361b26a8',
            characteristicOutputUuid: 'beb5483e-36e1-4688-b7f5-ea07361b26a9'
        }
    }
};
