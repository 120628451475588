import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { PmrSounds, TeamUnderOperation } from '@lightning/lightning-definitions';

import { PmrService } from 'apps/headquarter/src/app/apps/shared/services/pmr/pmr.service';
import { RegisterService } from 'apps/headquarter/src/app/apps/shared/services/register/register.service';

@Component({
    selector: 'app-checking',
    templateUrl: './checking.component.html',
    styleUrls: ['./checking.component.scss']
})

export class CheckingComponent implements OnInit, OnDestroy {

    public isStarted: boolean = false;
    public isCheckingUsedChannelOnly: boolean = false;

    public channelIndex: number = 0;
    public channelName: string = '';

    private onTransmittingChangeSubscription: Subscription;

    constructor(public pmrService: PmrService, 
                public registerService: RegisterService) { 

        this.onTransmittingChangeSubscription = this.pmrService.onTransmittingChange
            .subscribe(state => this.pmrTransmittingChange(state));
    }

    ngOnInit(): void {

        // If there is some teams, check only its channels by default
        this.isCheckingUsedChannelOnly = this.registerService.teams.length > 0;

        
    }

    ngOnDestroy(): void {

        this.onTransmittingChangeSubscription.unsubscribe();
    }

    public start(): void {

        this.channelIndex = 0;

        this.isStarted = true;

        this.next();
    }

    public stop(): void {

        this.isStarted = false;
    }

    public pmrTransmittingChange(state: any): void {

        if (state.isTransmitting === false) {
            this.next();
        }
    }

    public next(): void {

        if (this.isStarted === false) {

            return;
        }

        const teams = this.isCheckingUsedChannelOnly ? this.registerService.teams : this.registerService.teamsSlots;

        if (this.channelIndex < teams.length) {

                const team: TeamUnderOperation =  teams[this.channelIndex];
    
                this.channelName = team.name;
    
                this.pmrService.play((PmrSounds as any)['PmrCheck' + team.name], teams[this.channelIndex].pmr);
    
                this.channelIndex++;
    
        } else {

            this.channelName = 'Broadcast';

            this.pmrService.play(PmrSounds.PmrCheckBroadcast, this.pmrService.broadcastChannel);

            this.channelIndex = 0;
        }
        
    }

}
