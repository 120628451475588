<app-container>

    <app-header
        [logo]="'assets/apps/updates/logo.svg'"
        [description]="'apps.updates.headerDescription' | translate">
    </app-header>

    @if(gatewayService.isConnected === false) {

        <app-disclamer
            level="error"
            [isInteractive]="true"
            (click)="settingsService.requestWifiCredentials()">
            {{ ('apps.updates.disclamers.gatewayRequired' | translate: { ssid: settingsService.settings.wifi.ssid })  | capitalize }}
        </app-disclamer>

    } @else if(settingsService.settings.wifi.ssid) {

        @if(isWifiReacheable === false) {
            <app-disclamer
                level="warning"
                [isInteractive]="true"
                (click)="settingsService.requestWifiCredentials()">
                {{ ('apps.updates.disclamers.wifiEnsureIsReachable' | translate: { ssid: settingsService.settings.wifi.ssid })  | capitalize }}
            </app-disclamer>
        }

    } @else {
        <app-disclamer
            level="error"
            [isInteractive]="true"
            (click)="settingsService.requestWifiCredentials()">
            {{ 'apps.updates.disclamers.wifiNoCredentials' | translate  | capitalize }}
        </app-disclamer>
    }

    <app-body>

        <!-- Placeholders -->
        @if(!gatewayService.state) {

            <wui-placeholder *ngIf="loraProtocolService.devices.length === 0">
                {{ (loraProtocolService.isConnected ?
                    'apps.lora.devices.noItemDetectedYetPlaceholder' :
                    'apps.lora.devices.noItemGatewayDisconnectedPlaceholder')
                | translate }}
            </wui-placeholder>

        } @else {

            <div class="header">
                <div class="cell id">{{ 'shared.identifier'   | translate }}</div>
                <div class="cell product">{{ 'shared.product' | translate }}</div>
                <div class="cell current">{{ 'shared.current' | translate }}</div>
                <div class="cell minimal">{{ 'shared.minimal' | translate }}</div>
                <div class="cell minimal">{{ 'shared.latest'  | translate }}</div>
                <div class="cell button">
                    <wui-button
                        (click)="loadLatestVersions()">
                        ⟳
                    </wui-button>
                </div>
            </div>

            <!-- Gateway -->
            <div class="line">
                <div class="cell id">{{ gatewayService.state.id }}</div>
                <div class="cell product">{{ ProductsWithSoftware.HeadquarterGateway }}</div>
                <div class="cell current"
                    [class.pulsing]="!isCompatibleGatewaySofware()"
                    [class.invalid]="!isCompatibleGatewaySofware()">
                    {{ gatewayService.state.software }}
                </div>
                <div class="cell minimal">{{ MinimalCompatibleVersions[ProductsWithSoftware.HeadquarterGateway] }}</div>
                <div class="cell minimal">{{ latestVersions.get(ProductsWithSoftware.HeadquarterGateway) || '?'  }}</div>
                <div class="cell button"
                    (click)="updateGateway()"> <!-- clickable in any case ^^ -->
                    @if(gatewayService.state.software === latestVersions.get(ProductsWithSoftware.HeadquarterGateway)) {
                        <div class="up-to-date">
                            {{ 'shared.upToDate' | translate }}
                        </div>
                    } @else {
                        <wui-button>
                            {{ 'shared.update' | translate }}
                        </wui-button>
                    }
                </div>
            </div>

            <!-- Lora devices -->
            @for(device of loraProtocolService.devices; track device) {
                @if(device.state && device.state.product) {
                    <div class="line">
                        <div class="cell id">
                            {{ device.id }}

                            @if(device.name) {
                                <br/>
                                {{ device.name }}
                            }
                        </div>
                        <div class="cell product">
                            {{ device.state.product }}
                        </div>
                        <div class="cell current"
                            [class.pulsing]="!isCompatibleLoraDeviceSofware(device)"
                            [class.invalid]="!isCompatibleLoraDeviceSofware(device)">
                                {{ device.state.software || '-'}}
                        </div>
                        <div class="cell minimal">
                            {{ MinimalCompatibleVersions[device.state.product] }}
                        </div>
                        <div class="cell latest">
                            {{ latestVersions.get(device.state.product) || '?' }}
                        </div>
                        <div class="cell button"
                            (click)="updateLoraDevice(device)"> <!-- clickable in any case ^^ -->
                            @if(device.state.software === latestVersions.get(device.state.product)) {
                                <div class="up-to-date">
                                    {{ 'shared.upToDate' | translate }}
                                </div>
                            } @else {
                                <wui-button>
                                    {{ 'shared.update' | translate }}
                                </wui-button>
                            }
                        </div>

                    </div>
                }
            }
        }

    </app-body>

    <app-footer>
        <wui-buttons>
            <!-- <wui-button
                (click)="openReleaseNotes()">
                {{ 'shared.releaseNotes' | translate }}
            </wui-button>
            <wui-button
                (click)="updateAll()">
                {{ 'shared.updateAll' | translate }}
        </wui-button> -->
        </wui-buttons>
    </app-footer>

</app-container>
