import { Injectable } from '@angular/core';
import { LoraDevice, OperationsRoles, NfcTagsSpecials, OperationActions, PmrSounds, Products } from '@lightning/lightning-definitions';
import { NfcSpecialData } from '../../../../shared/enums/lora.enum';
import { OperationBase } from '../../shared/classes/operation.base';
import { OperationStates } from '../../shared/enums/operation.enums';
import { OperationRequierements, OperationRequierement } from '../../shared/interfaces/operation.interface';

export enum DestructionSubModes {
    Directional,
    Crossed
}

@Injectable({
    providedIn: 'root'
})

export class DestructionService extends OperationBase {

    constructor() {

        super('destruction');

        this.settings = {
            version: 1,

            name: 'Destruction op',
            timeLimit: 0,

            submode: DestructionSubModes.Crossed
        };

        this.organisatorActions = [
            this.getOrganisatorActionNote()
        ];


        ////////////////////////////////////////////////////////////////////////
        // CAUTION: AWAYS LISTENING EVEN IF THIS MODE WAS NOT SELECTED

        this.loraProtocolService.onNfcSpecialReceive
            .subscribe((data: NfcSpecialData) => this.onNfcSpecialReceive(data));

        this.loraProtocolService.onDeviceUsable
            .subscribe(device => this.loraOnDeviceUsable(device));

        this.loraProtocolService.onDeviceUnusable
            .subscribe(device => this.loraOnDeviceUnusable(device));
    }


    public override getRequierements(): OperationRequierements {

        const groundModules = this.loraProtocolService.getDevicesByProduct(Products.GroundModule);

        // Describing requierements
        const requierements: Array<OperationRequierement> = [
            {
                name: 'Count of teams 2',
                value: this.registerService.teams.length,
                isReady: this.registerService.teams.length === 2,
                help: '',
            },
            {
                name: 'Ground modules 2+',
                value: groundModules.length.toString(),
                isReady: groundModules.length >= 2,
                help: '',
            }
        ];

        // Check if all requierements are ready
        const isReady = requierements.some(requierement => requierement.isReady === false) === false;

        return { requierements, isReady };
    }

    public override settingsAreValid(): boolean {

        return true;
    }

    public override settingsApply(): void {

        // Timer setup
        // this.timer.setCountDown(this.settings.timeLimit); // TODO : REMOVE IT FOR COUNTER

        this.loraProtocolService
            .getDevicesByProduct(Products.GroundModule)
            .map(groundModule => {
                groundModule.role = OperationsRoles.Target;
            });

        // Save settings of the next time
        this.settingsSave();
    }

    public override start(): void {

        this.timer.startChronometer(); console.log(this.settings.submode);

        super.start();
    }

    private async onNfcSpecialReceive(data: NfcSpecialData): Promise<void> {

        const { sender, operator, special } = data;

        // Check the operation is processing
        if (this.state !== OperationStates.Processing) {
            return;
        }

        // Ensure the operator has a team (use or ask for)
        const team = data.team || await this.registerService.askOperatorTeam(operator);

        if (!team) {
            return;
        }

        // Check the item can destroy the target
        if (special !== NfcTagsSpecials.Explosive) {

            this.environmentService.notificationOpen({
                logo: 'assets/apps/operations/logo.svg',
                message: `The operator ${operator.name} tried to destroy the area ${sender.name} with a special type ${special}`,
                color: 'yellow'
            });

            return;
        }

        // Check the target was not already destroyed
        if (sender.role !== OperationsRoles.Target) { // TODO: Create an enum for roles (according to the assets)

            this.environmentService.notificationOpen({
                logo: 'assets/apps/operations/logo.svg',
                message: `The operator ${operator.name} tried to destroy the area ${sender.name} which is already destroyed`,
                color: 'yellow'
            });

            return;
        }

        // -------------------------------------------------------------------------
        // Normal case

        this.timelinePush({
            level: 1,
            action: OperationActions.AreaDestroyed,
            data: {
                operator, team, area: sender.name,
            }
        });

        // Announce via PMR
        this.pmrService.announce(PmrSounds.Explosion);
        this.pmrService.announce((PmrSounds as any)['AreaDestroyed' + sender.name]);
        this.pmrService.announce((PmrSounds as any)['TeamBy' + team?.name]); // USELESS?

        sender.role = undefined;

        switch(this.settings.submode) {

            case DestructionSubModes.Directional:

                // If all areas are destroyed
                if (this.loraProtocolService
                    .getDevicesByProduct(Products.GroundModule)
                    .some(groundModule => groundModule.role === OperationsRoles.Target) === false) {

                    this.complete();
                }

            break;

            case DestructionSubModes.Crossed:

                // The first destruction completes the operation
                this.complete();

            break;
        }

    }

    private loraOnDeviceUsable(device: LoraDevice) {

        // console.log('xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx')
        // console.log(device)
        // console.log(device.state);

        // // CAUTION: In some cases, state is not available yet
        // if (device.state?.product === DevicesTypes.GroundModule) {

        //     console.log('HIHIIII')

        //     device.role = OperationsRoles.Target;
        // }
    }

    private loraOnDeviceUnusable(device: LoraDevice) {

        // if(device.role === OperationsRoles.Target) {
        //     // WARNING: A MODULE DESIGNED AS TARGET WAS LOST
        // }
    }


}
