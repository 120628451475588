<app-container>
    <app-header logo="assets/apps/reports/logo.svg" [description]="'apps.reports.headerDescription' | translate"></app-header>
    <app-body>

        <app-disclamer
            *ngIf="!onlineService.isAvailableAndLoggedIn"
            level="warning"
            (click)="environmentService.windowOpenByAppId('online'); $event.stopPropagation()"
            [isInteractive]="true">
           {{ 'apps.reports.onlineRequiredForUpload' | translate  | capitalize }}
        </app-disclamer>

        <wui-placeholder *ngIf="reportsService.reports.length === 0">
            {{ 'apps.reports.noSavedReports' | translate }}
        </wui-placeholder>

        <div class="reports">
            <wui-section *ngIf="reportsService.reports.length" [name]="'apps.reports.sectionSavedReports' | translate">
                <wui-expendable wuiTacticalCorners *ngFor="let report of reportsService.reports">
                    <div preview class="preview">
                        <div class="logo" [wuiBackgroundImage]="'assets/operations/modes/' + report.mode + '/logo.svg'"></div>
                        <div class="information">
                            <div class="title">
                                <div class="name">
                                    {{ report.timeline[0].time | date : 'yyyy.MM.dd  HH:mm' }}
                                </div>

                                <div class="upload"
                                    *ngIf="!report.id"
                                    [wuiDisabled]="!onlineService.isAvailable"
                                    [title]="'shared.upload' | translate"
                                    (click)="reportsService.upload(report); $event.stopPropagation()"></div>

                                <div class="open"
                                    *ngIf="report.id"
                                    [wuiDisabled]="!onlineService.isAvailable"
                                    [title]="'shared.open' | translate"
                                    (click)="reportsService.openOnline(report); $event.stopPropagation()"></div>

                                <div class="remove"
                                    [title]="'shared.remove' | translate"
                                    (click)="reportsService.remove(report); $event.stopPropagation()"></div>
                            </div>
                            <div class="properties">

                                <div class="property">
                                    {{ report.mode }}
                                </div>

                                <div class="property">
                                    <!-- <img src="assets/apps/lora/properties/activity.svg"/> -->
                                    {{ report.operators.length }} {{ 'shared.operators' | translate }}
                                </div>
                                <div class="property">
                                    <!-- <img src="assets/apps/lora/properties/activity.svg"/> -->
                                    {{ report.timeline[0].context.teams.length }} {{ 'shared.teams' | translate }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div content class="content">

                        <lui-operation-report
                            #reportComponent
                            [report]="report"
                            [customIconsRootUrl]="onlineService.backendUrl"
                            (trophyClick)="trophyClick($event)">
                        </lui-operation-report>

                        <wui-buttons>

                            <wui-button
                                developpersOnly
                                (click)="reportsService.export(report)">
                                {{ 'shared.export' | translate }}
                            </wui-button>

                            <wui-button
                                (click)="reportComponent.toggleReplay()">
                                {{ 'shared.' + (reportComponent.isReplaying ? 'replaying' : 'replay') | translate }}
                            </wui-button>

                        </wui-buttons>

                        <pre developpersOnly>{{ report | json }}</pre>
                    </div>
                </wui-expendable>

            </wui-section>
        </div>

    </app-body>

    <app-footer>
        <wui-buttons>
            <wui-button
                developpersOnly
                (click)="reportsService.import()">
                {{ 'shared.import' | translate }}
            </wui-button>
            <wui-button
                (click)="reportsService.clear()">
                {{ 'shared.clear' | translate }}
            </wui-button>
            <!-- <wui-button>{{ 'shared.uploadAll' | translate }}</wui-button> -->
        </wui-buttons>
    </app-footer>



</app-container>
