<app-body>

    <wui-section [name]="'shared.description' | translate">
        {{ 'apps.pmr.checking.description' | translate }}
    </wui-section>

    <wui-section [name]="'shared.options' | translate">
        <wui-entry>
            <div name> {{ 'apps.pmr.checking.channels' | translate }}</div>
            <wui-select value [(value)]="isCheckingUsedChannelOnly">
                <option value="false">{{ 'apps.pmr.checking.all' | translate }}</option>
                <option value="true">{{ 'apps.pmr.checking.used' | translate }}</option>
            </wui-select>
        </wui-entry>
    </wui-section>

    <wui-section
        class="checking"
        *ngIf="isStarted"
        [name]="'apps.pmr.checking.checking' | translate: { channelName }"

        [wuiTacticalCorners]="pmrService.state.isTransmitting === true"
        [wuiTacticalCornersOptions]="{infinite: true}">

        <div class="pmr" *ngIf="pmrService.state.isTransmitting === true" >{{pmrService.state.txChannel}}</div>
    </wui-section>

</app-body>
<app-footer>
    <wui-buttons>
        <wui-button *ngIf="!isStarted" (click)="start()">{{ 'shared.start' | translate }}</wui-button>
        <wui-button *ngIf=" isStarted" (click)="stop()">{{ 'shared.stop' | translate }}</wui-button>
    </wui-buttons>
</app-footer>
