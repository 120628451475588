<!-- desktop -->
<div class="desktop" (click)="environmentService.toastsClear()">

    <!-- windows -->
    <div class="windows">
        <wild-window *ngFor="let window of environmentService.windows"
            [data]="window"
            (mousedown)="environmentService.windowFocus(window)"
            (closing)="environmentService.closeWindow(window)"
            (changed)="environmentService.sessionSave()"></wild-window>
    </div>

    <!-- panels -->
    <div class="panels" *ngIf="panels.isStartDisplayed || panels.isNotificationsDisplayed" (click)="closePanels()">

        <!-- compact side start panel -->
        <!-- <div class="start" *ngIf="panels.isStartDisplayed" wuiTacticalCorners [wuiTacticalCornersOptions]="{noLeft: true}">
            <div class="items">
                <div class="app" *ngFor="let app of environmentService.manifests"
                    (click)="environmentService.openWindow(app)">
                    <div class="icon" [wuiBackgroundImage]="'assets/apps/' + app.id + '/icon.svg'">
                        <div class="badge"></div>
                    </div>
                    <div class="text">
                        <div class="name">{{app.name}}</div>
                        <div class="description">{{app.description}}</div>
                    </div>
                </div>
            </div>
        </div> -->

        <!-- start panel -->
        <div class="start2" *ngIf="panels.isStartDisplayed">
            <input
                #searchField
                type="text"
                class="search"
                [class.searching]="searchValue"
                [ngModel]="searchValue"
                (ngModelChange)="search($event)"
                (keydown.enter)="confirmSearch()">
            <wui-cards *ngIf="searchedManifests.length">
                <wui-card
                    *ngFor="let app of searchedManifests"
                    [title]="app.name"
                    [description]="app.description"
                    [logo]="'assets/apps/' + app.id + '/icon.svg'"
                    (click)="openWindow(app)">
                </wui-card>
            </wui-cards>
        </div>

        <!-- notifications -->
        <div class="notifications" *ngIf="panels.isNotificationsDisplayed" wuiTacticalCorners [wuiTacticalCornersOptions]="{noRight: true}">
            <div class="items">
                <div *ngFor="let notification of environmentService.notifications; index as index;"
                    class="notification"
                    [style.backgroundColor]="notification.color"
                    [wuiScroll]="index === environmentService.notifications.length - 1">
                        <div class="logo" [wuiBackgroundImage]="notification.logo"></div>
                        <div class="text">
                            <div class="message">{{notification.message}}</div>
                            <div class="time">{{notification.date | date: 'HH:mm:ss'}}</div>
                        </div>
                </div>
            </div>
        </div>

    </div>

</div>

<!-- bar -->
<div class="bar" (dblclick)="toggleFullscreen()">

    <!-- start button -->
    <div class="start" (click)="toggleStartPanel()"></div>

    <!-- opened apps -->
    <div class="windows">
        <div class="window"
            *ngFor="let window of environmentService.windows"
            [ngClass]="window.display.state"
            [wuiBackgroundImage]="'assets/apps/' + window.manifest.id + '/logo.svg'"
            (click)="windowInBarClick(window)"
            (wuiPointerLong)="environmentService.closeWindow(window)">
        </div>
    </div>

    <!-- tray -->
    <ng-container *ngComponentOutlet="environmentService.tray?.component"></ng-container>

    <!-- clock -->
    <div class="clock" (click)="changeClockFormat()">
        {{clock.date | date: clock.formats[clock.formatIndex]}}
    </div>

    <!-- notifications button -->
    <div class="notifications" *ngIf="environmentService.notifications.length" (click)="toggleNotificationsPanel()"></div>
</div>


<!-- toast (lastest notifications)-->
<div class="toasts">
    <div *ngFor="let toast of environmentService.toasts; index as index"
        class="toast"
        [style.backgroundColor]="toast.color"
        [style.cursor]="toast.callback ? 'pointer' : 'default'"
        [wuiScroll]="index === environmentService.toasts.length - 1"
        (click)="environmentService.toastClick(toast)">
        <div class="logo" *ngIf="toast.logo" [wuiBackgroundImage]="toast.logo"></div>
        <div class="message">{{toast.message}}</div>
    </div>
</div>

<!-- modals -->
<div class="modals" id="modals" *ngIf="environmentService.modals.length > 0" (click)="modalClick($event)"> <!--(click)="modalClose()"-->
    <wild-modal *ngFor="let modal of environmentService.modals" [data]="modal"></wild-modal>
</div>
