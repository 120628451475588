import { Component, OnInit } from '@angular/core';

import { ProductsWithSoftware } from '@lightning/lightning-definitions';

import { LoraProtocolService } from '../../../shared/services/lora-protocol/lora-protocol.service';

@Component({
    selector: 'app-commands',
    templateUrl: './commands.component.html',
    styleUrls: ['./commands.component.scss']
})

export class CommandsComponent {

    public colorDeviceId = '0';
    public colorValue = '#000000';
    public colorDuration = 0;
    public colorRainbowValue = '';
    public colorSequenceTimer: any = undefined;
    public colorSequenceIndex = 0;

    public updateDeviceId = '0';

    constructor(public loraProtocolService: LoraProtocolService) { }

    public sendColorCommand(): void {

        this.loraProtocolService.sendColorCommand(this.colorValue, this.colorDuration, this.colorDeviceId);
    }

    public startColorSequence(): void {

        this.colorSequenceIndex = 0;

        this.colorSequenceTimer = setInterval(() => {

            const groundModules = this.loraProtocolService.getDevicesByProduct(ProductsWithSoftware.GroundModule);

            if(groundModules.length === 0) {

                return;
            }

            if (++this.colorSequenceIndex >= groundModules.length) {

                this.colorSequenceIndex = 0;
            }

            const groundModule = groundModules[this.colorSequenceIndex];

            const color = '#' + (Math.random()*0xFFFFFF<<0).toString(16);

            console.log(groundModule, this.colorSequenceIndex);

            this.loraProtocolService.sendColorCommand(color, 4000, groundModule.id);

        }, 3000);

    }

    public stopColorSequence(): void {

        if (!this.isColorSequenceStarted) {
            return;
        }

        clearInterval(this.colorSequenceTimer);

        this.colorSequenceTimer = undefined;
    }

    public get isColorSequenceStarted(): boolean {

        return this.colorSequenceTimer !== undefined;
    }

    public sendDiscoveryCommand(): void {

        this.loraProtocolService.sendDiscoveryCommand();
    }

    public sendRangeTestCommand(): void {

        this.loraProtocolService.sendRangeTestCommand();
    }

}
