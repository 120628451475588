import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TrayComponent } from './components/tray/tray.component';

import { EnvironmentService } from '@lightning/wild-environment';

@NgModule({
    declarations: [
        TrayComponent
    ],
    imports: [
        CommonModule
    ]
})

export class TrayModule {

    constructor(environmentService: EnvironmentService) {

        // Registration
        environmentService.trayRegister({
            component: TrayComponent
        });
    }
}
