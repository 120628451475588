<app-container>

    <app-header
        [logo]="'assets/apps/pmr/logo.svg'"
        [description]="'apps.pmr.headerDescription' | translate | capitalize">
    </app-header>

    <app-disclamer
        *ngIf="!gatewayService.isConnected"
        level="error"
        (click)="environmentService.windowOpenByAppId('gateway'); $event.stopPropagation()">
        {{ 'apps.pmr.dislacamers.gatewayRequired' | translate }}
    </app-disclamer>

    <wui-tabs [tabs]="tabs" developpersOnly></wui-tabs>

    <ng-container *ngIf="tabs.selected">
        <ng-container *ngComponentOutlet="tabs.selected.component"></ng-container>
    </ng-container>


</app-container>
