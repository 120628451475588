import { Injectable } from '@angular/core';
import { TeamUnderOperation, OperationWinner, OperationTrophy, OperationTrophiesTypes, OperationsRoles, OperationActions, PmrSounds, LoraDevice, OperatorAvatarName, Products } from '@lightning/lightning-definitions';
import { NfcOperatorData } from '../../../../shared/enums/lora.enum';
import { OperationBase } from '../../shared/classes/operation.base';
import { OperationStates } from '../../shared/enums/operation.enums';
import { OperationRequierements, OperationRequierement, OperationOrganisatorAction } from '../../shared/interfaces/operation.interface';


@Injectable({
    providedIn: 'root'
})

export class DropsService extends OperationBase {

    constructor() {

        super('drops');

        this.settings = {
            version: 1,

            name: 'Drops op',
            timeLimit: 1800000,

            pointsDynamic: true,
            pointsLimit: 1000000,
        };

        this.organisatorActions = [
            this.getOrganisatorActionNote(),
            this.getOrganisatorActionTeamBonusPenality(),
            this.getOrganisatorActionChangeDrop()
        ];

        this.loraProtocolService.onNfcOperatorReceive.
            subscribe((data: NfcOperatorData) => this.onNfcOperatorReceive(data));

        this.loraProtocolService.onDeviceUnusable
            .subscribe(device => this.loraOnDeviceUnusable(device));

        this.registerService.onTeamsRankingLeadChanged
            .subscribe((team: TeamUnderOperation) => this.teamsRankingLeadChanged(team));
    }


    public override getRequierements(): OperationRequierements {

        // Get grounds modules
        const groundModules = this.loraProtocolService.getDevicesByProduct(Products.GroundModule);

        // Describing requierements
        const requierements: Array<OperationRequierement> = [
            {
                name: 'Count of teams 2-4',
                value: this.registerService.teams.length,
                isReady: this.registerService.teams.length >= 2 && this.registerService.teams.length <= 4,
                help: '',
            },
            {
                name: 'Ground modules 3+',
                value: groundModules.length.toString(),
                isReady: groundModules.length >= 3,
                help: '',
            }
        ];

        // Check if all requierements are ready
        const isReady = requierements.some(requierement => requierement.isReady === false) === false;

        return { requierements, isReady };
    }

    public override settingsAreValid(): boolean {

        return true;
    }

    public override settingsApply() {

        // Timer setup
        this.timer.setCountDown(this.settings.timeLimit);

        // Save settings of the next time
        this.settingsSave();
    }

    public override getWinner(): OperationWinner | undefined {

        return this.getWinnerByTeamRank();
    }

    public override getTrophies(): Array<OperationTrophy> {

        const trophies: Array<OperationTrophy> = [];

        let operators = this.registerService.operators.filter(operator => operator.data.stats);

        // 2 active operators or more
        if (operators.length < 2) {
            return trophies;
        }

        // First one
        trophies.push({
            type: OperationTrophiesTypes.DropsFirstOne,
            data: {
                operator: operators.find(operator => operator.data.stats?.isFirst)
            }
        });

        // Biggest total without equality
        operators = operators.sort((a, b) => { return (b.data.stats?.total || 0) - (a.data.stats?.total || 0) });

        if((operators[0].data.stats?.total || 0) > (operators[1].data.stats?.total || 0)) {
            trophies.push({
                type: OperationTrophiesTypes.DropsBiggestTotal,
                data: {
                    operator: operators[0]
                }
            });
        }

        return trophies;
    }

    public override start() {

        super.start();

        this.doNextDrop();
    }


    public getOrganisatorActionChangeDrop(): OperationOrganisatorAction {

        return {
            title: 'change drop point',
            logo: 'assets/apps/operations/drops/logo.svg',
            description: 'for a rondom other one',
            data: {
                process: async () => {
                    this.doNextDrop();
                }
            }
        };

    }


    private async onNfcOperatorReceive(data: NfcOperatorData): Promise<void> {

        const { sender, operator } = data;

        // Check the operation is processing
        if (this.state !== OperationStates.Processing) {
            return;
        }

        // Ensure the operator has a team (use or ask for)
        const team = data.team || await this.registerService.askOperatorTeam(operator);

        if (!team) {
            return;
        }

        // Check for staff team
        if (team.isStaff) {

            this.environmentService.notificationOpen({
                logo: 'assets/apps/operations/logo.svg',
                message: 'The staff member ' + operator.name + ' tested the area' + sender.name,
                color: 'yellow'
            });

            return;
        }

        // Check the role of the areais a drop
        if (sender.role !== OperationsRoles.Drop) {

            this.environmentService.notificationOpen({
                logo: 'assets/apps/operations/logo.svg',
                message: 'The operator ' + operator.name + ' tried to catch something in a wrong area (module ' + sender.name + ')',
                color: 'yellow'
            });

            return;
        }

        // -------------------------------------------------------------------------
        // Normal case

        const points = this.calculateDropPoints(sender, team);

        // Increase team points and update ranking
        this.registerService.increaseTeamScore(team, points);

        // Store data for trophies calculations
        const isFirst: boolean =
            this.timeline.filter(item => item.action === OperationActions.DropCatched).length === 0;

        operator.data.stats = operator.data.stats || { isFirst: false, total: 0 };
        operator.data.stats.isFirst = isFirst ? true : operator.data.stats.isFirst;
        operator.data.stats.total = (operator.data.stats.total || 0) + 1;

        // Fill the timeline
        this.timelinePush({
            level: 1,
            action: OperationActions.DropCatched,
            data: {
                operator, team, area: sender.name, points
            },
            customIconPath:
                this.onlineService.getOperatorAvatarPath(operator.number, OperatorAvatarName.Winning)
        });


        // Announce via PMR
        // this.pmrService.announce(PmrSounds['AreaDropCatched' + sender.name]);
        this.pmrService.announce(PmrSounds.AreaDropCatched);
        this.pmrService.announce((PmrSounds as any)['TeamBy' + team.name]);

        // -------------------------------------------------------------------------
        // Points limit reached

        if (this.settings.pointsLimit > 0 && data.team && data.team.score.points >= this.settings.pointsLimit) {

            this.complete();

            return;
        }

        // Set the next target (the previous pmr announces doesn't work without this timeout Oo)
        setTimeout(this.doNextDrop.bind(this), 1000);

    }

    private loraOnDeviceUnusable(device: LoraDevice): void {

        // Renew the drop if lost
        if (device.state?.product === Products.GroundModule && device.role === OperationsRoles.Drop) {

            this.environmentService.notificationOpen({
                logo: 'assets/apps/operations/logo.svg',
                message: 'Caution: The ground module designated as drop area was lost, another one will be selected.',
                color: 'orangered'
            });

            this.doNextDrop();
        }
    }

    private teamsRankingLeadChanged(team: TeamUnderOperation): void {

        // Check the operation is processing
        if (this.state !== OperationStates.Processing) {
            return;
        }

        // Announce via PMR
        this.pmrService.announce((PmrSounds as any)['TeamLeads' + team.name]);
    }


    private doNextDrop(): void {

        // Get ground modules
        const groundModules: Array<LoraDevice> = this.loraProtocolService.getDevicesByProduct(Products.GroundModule);

        // Find the current drop ground module
        const currentDropGroundModule = groundModules.find(item => item.role === OperationsRoles.Drop);

        // Clear the led of the current drop ground module
        if(currentDropGroundModule) {

            this.loraProtocolService.sendColorCommand('#000000', 0, currentDropGroundModule.id);
        }

        // Eligible if role free
        const eligibleGroundModules: Array<LoraDevice> = groundModules.filter(item => item !== currentDropGroundModule);

        // No eligible area
        if (eligibleGroundModules.length === 0) {

            this.environmentService.notificationOpen({
                logo: 'assets/apps/operations/logo.svg',
                message: 'Caution: There is no enough ground modules to define another drop area',
                color: 'red'
            });

            return;
        }

        // Clear all ground modules roles
        groundModules.map(item => item.role = undefined);

        // Get an elibible module randomly
        const targetGroundModule = eligibleGroundModules[Math.floor(Math.random() * eligibleGroundModules.length)];

        // Set it as drop
        targetGroundModule.role = OperationsRoles.Drop;

        // Send a color command to the module
        this.loraProtocolService.sendColorCommand('#FFFFFF', 0, targetGroundModule.id);

        // Fill the timeline
        this.timelinePush({
            level: 1,
            action: OperationActions.DropReleased,
            data: {
                area: targetGroundModule.name
            }
        });

        // Announce via PMR
        this.pmrService.announce((PmrSounds as any)['AreaDrop' + targetGroundModule.name]);
    }

    private calculateDropPoints(groundModule: LoraDevice, team: TeamUnderOperation): number {

        if (this.settings.pointsDynamic === false) {
            return 10000;
        }

        if (!team.data.areas) {
            team.data.areas = [];
        }

        let areaValue = team.data.areas.find((value: any) => value.id === groundModule.id);

        if (!areaValue) {

            areaValue = { id: groundModule.id, value: 10 };

            team.data.areas.push(areaValue);
        }

        const points = areaValue.value * 1000;

        if (areaValue.value > 2) {
            areaValue.value -= 2;
        }

        return points;
    }

}
