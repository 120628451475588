import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ScreenService {

    get isMobile() {
        return window.matchMedia('screen and (max-width:1000px), (any-pointer: coarse)').matches;
    }

    get isDesktop() {
        return !this.isMobile;
    }

    public get isInstalled() {
        return window.matchMedia('(display-mode: browser)').matches == false;
    }

    public enterFullscreen() {
        document.documentElement.requestFullscreen();
    }

    public exitFullscreenCancel() {
        document.exitFullscreen();
    }

    public toggleFullscreen() {

        if (this.isFullscreen) {
            this.exitFullscreenCancel();
        } else {
            this.enterFullscreen();
        }
    }

    public get isFullscreen() {
        return document.fullscreenElement != null;
    }

}
