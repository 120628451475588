<app-body>

    <wui-section name="color">
        <wui-entry>
            <div name>Device</div>
            <wui-select value [(value)]="colorDeviceId">
                <option *ngFor="let item of loraProtocolService.devices" [value]="item.id">{{item.name || item.id}}</option>
                <option value="0">broadcast</option>
            </wui-select>
        </wui-entry>
        <wui-entry>
            <div name>Value</div>
            <wui-input value type="color" [(value)]="colorValue"></wui-input>
        </wui-entry>
        <wui-entry>
            <div name>Duration</div>
            <wui-select value [(value)]="colorDuration">
                <option value="0">infinite</option>
                <option value="5000">5s</option>
                <option value="10000">10s</option>
            </wui-select>
        </wui-entry>
        <wui-entry>
            <div name></div>
            <wui-button value (click)="sendColorCommand()">send</wui-button>
        </wui-entry>
        <wui-entry>
            <div name></div>
            <wui-button value *ngIf="!isColorSequenceStarted" (click)="startColorSequence()">start sequence</wui-button>
            <wui-button value *ngIf="isColorSequenceStarted" (click)="stopColorSequence()">stop sequence</wui-button>
        </wui-entry>
    </wui-section>

    <wui-section name="utilities">
        <wui-entry>
            <div name>Discovery</div>
            <wui-button value (click)="sendDiscoveryCommand()">send</wui-button>
        </wui-entry>
        <wui-entry>
            <div name>Range test</div>
            <wui-button value (click)="sendRangeTestCommand()">send</wui-button>
        </wui-entry>
    </wui-section>





</app-body>

<!-- <app-footer>
    <wui-buttons>
        <wui-button (click)="" >rainbow</wui-button>
    </wui-buttons>
</app-footer> -->
