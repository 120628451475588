import { EnvironmentService, ScreenService } from '@lightning/wild-environment';

import { AboutComponent } from './about/components/about/about.component';
import { AboutModule } from './about/about.module';
import { BotComponent } from './bot/components/bot/bot.component';
import { BotModule } from './bot/bot.module';
import { ExternalComponent } from './external/components/external/external.component';
import { ExternalModule } from './external/external.module';
import { GatewayComponent } from './gateway/components/gateway/gateway.component';
import { GatewayModule } from './gateway/gateway.module';
import { LoraComponent } from './lora/components/lora/lora.component';
import { LoraModule } from './lora/lora.module';
import { NgModule } from '@angular/core';
import { OnlineComponent } from './online/components/online/online.component';
import { OnlineModule } from './online/online.module';
import { OperationsComponent } from './operations/components/operations/operations.component';
import { OperationsModule } from './operations/operations.module';
import { PhotographiesComponent } from './photographies/components/photographies/photographies.component';
import { PhotographiesModule } from './photographies/photographies.module';
import { PmrComponent } from './pmr/components/pmr/pmr.component';
import { PmrModule } from './pmr/pmr.module';
import { RegisterComponent } from './register/components/register/register.component';
import { RegisterModule } from './register/register.module';
import { ReportsComponent } from './reports/components/reports/reports.component';
import { ReportsModule } from './reports/reports.module';
import { SandboxComponent } from './sandbox/components/sandbox/sandbox.component';
import { SandboxModule } from './sandbox/sandbox.module';
import { UpdatesComponent } from './updates/components/about/updates.component';
import { SettingsComponent } from './settings/components/settings/settings.component';
import { SettingsModule } from './settings/settings.module';
import { UpdatesModule } from './updates/updates.module';
import { SettingsService } from './shared/services/settings/settings.service';
import { SimulationComponent } from './simulation/components/simulation/simulation.component';
import { SimulationModule } from './simulation/simulation.module';
import { TranslateService } from '@ngx-translate/core';


@NgModule({
    declarations: [],
    imports: [
        AboutModule,
        ExternalModule,
        OnlineModule,
        LoraModule,
        PmrModule,
        OperationsModule,
        RegisterModule,
        ReportsModule,
        SandboxModule,
        PhotographiesModule,
        GatewayModule,
        BotModule,
        SimulationModule,
        UpdatesModule,
        SettingsModule,
    ],
    exports: [],
    providers: [],
})


export class AppsModule {

    constructor(
        environmentService: EnvironmentService,
        screenService: ScreenService,
        translateService: TranslateService,
        settingsService: SettingsService) {

        // Grouped app registration example
        environmentService.appRegister([
            {
                id: 'simulation',
                name: 'simulation',
                description: 'simulate external actions',
                component: SimulationComponent,
                mobile: {},
                desktop: {
                    single: true,
                    limits: {
                        minWidth: 40,
                        minHeight: 40
                    }
                },
                argument: {}
            },
            {
                id: 'gateway',
                name: 'gateway',
                description: 'manage the connection with the gateway',
                component: GatewayComponent,
                mobile: {

                },
                desktop: {
                    single: true,
                    limits: {}
                },
                argument: {}
            },
            {
                id: 'lora',
                // name: translateService.instant('apps.lora.name'),
                name: 'long range devices',
                description: 'manage long range devices',
                component: LoraComponent,
                mobile: {

                },
                desktop: {
                    single: false,
                    limits: {}
                },
                argument: {}
            },
            {
                id: 'pmr',
                name: 'pmr radio',
                description: 'communicate with operators by pmr radio',
                component: PmrComponent,
                mobile: {

                },
                desktop: {
                    single: false,
                    limits: {}
                },
                argument: {}
            },
            {
                id: 'register',
                name: 'register',
                description: 'register and assign operators',
                component: RegisterComponent,
                mobile: {

                },
                desktop: {
                    single: true,
                    limits: {}
                },
                argument: {}
            },
            {
                id: 'operations',
                name: 'operations',
                description: 'create operations using wireless modules',
                component: OperationsComponent,
                mobile: {

                },
                desktop: {
                    single: true,
                    limits: {}
                },
                argument: {}
            },
            {
                id: 'reports',
                name: 'reports',
                description: 'manage operations data',
                component: ReportsComponent,
                mobile: {

                },
                desktop: {
                    single: true,
                    limits: {}
                },
                argument: {}
            },
            // {
            //     id: 'online',
            //     name: 'online',
            //     description: 'online features',
            //     component: OnlineComponent,
            //     mobile: {},
            //     desktop: {
            //         single: true,
            //         limits: {
            //             minWidth: 40,
            //             minHeight: 40
            //         }
            //     },
            //     argument: {}
            // },
            // {
            //     id: 'website',
            //     name: 'website',
            //     description: 'lightning website',
            //     component: ExternalComponent,
            //     mobile: {

            //     },
            //     desktop: {
            //         single: false,
            //         limits: {}
            //     },
            //     argument: { url: environment.website.url }
            // },
            // {
            //     id: 'sandbox',
            //     name: 'sandbox',
            //     description: 'sandbox for development',
            //     component: SandboxComponent,
            //     mobile: {

            //     },
            //     desktop: {
            //         single: true,
            //         limits: null
            //     },
            //     argument: {}
            // },
            {
                id: 'updates',
                name: 'updates',
                description: 'manage software updates',
                component: UpdatesComponent,
                mobile: {},
                desktop: {
                    single: true,
                    limits: {
                        minWidth: 40,
                        minHeight: 40
                    }
                },
                argument: {}
            },
            {
                id: 'settings',
                name: 'settings',
                description: 'set global settings',
                component: SettingsComponent,
                mobile: {},
                desktop: {
                    single: true,
                    limits: {
                        minWidth: 40,
                        minHeight: 40
                    }
                },
                argument: {}
            },
            // {
            //     id: 'about',
            //     name: 'about',
            //     description: 'about this software',
            //     component: AboutComponent,
            //     mobile: {},
            //     desktop: {
            //         single: true,
            //         limits: {
            //             minWidth: 40,
            //             minHeight: 40
            //         }
            //     },
            //     argument: {}
            // },
        ]);


        if (settingsService.isDeveloppersModeEnabled) {

            environmentService.appRegister([
                {
                    id: 'photographies',
                    name: 'photographies',
                    description: 'photographies tools',
                    component: PhotographiesComponent,
                    mobile: {},
                    desktop: {
                        single: true,
                        limits: {
                            minWidth: 40,
                            minHeight: 40
                        }
                    },
                    argument: {}
                },
                {
                    id: 'online',
                    name: 'online',
                    description: 'online services',
                    component: OnlineComponent,
                    mobile: {},
                    desktop: {
                        single: true,
                        limits: {
                            minWidth: 40,
                            minHeight: 40
                        }
                    },
                    argument: {}
                },
                {
                    id: 'bot',
                    name: 'nfc bot',
                    description: 'physical nfc testing',
                    component: BotComponent,
                    mobile: {

                    },
                    desktop: {
                        single: false,
                        limits: {}
                    },
                    argument: {}
                },
            ]);
        }

        environmentService.sessionReady.subscribe(() => {

            const starts = parseInt(localStorage.getItem('starts') || '0');

            // Open the simulation app for its tutorial on the first start on a desktop
            if(starts === 0 && screenService.isDesktop) {
                environmentService.windowOpenByAppId('simulation', { tab: 'tutorial' });
            }

            localStorage.setItem('starts', (starts + 1).toString());

        });

    }
}
