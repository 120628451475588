<app-container>
    <app-header
        [logo]="'assets/apps/gateway/logo.svg'"
        [description]="'apps.gateway.headerDescription' | translate">
    </app-header>
    <app-body>

        <!-- Is not started -->
        @if (gatewayService.isStarted === false) {
            <wui-placeholder>
                {{ 'apps.gateway.disconnectedPlaceholder' | translate }}
            </wui-placeholder>
        }

        <!-- Is connecting (started, not connected) -->
        @if (gatewayService.isConnecting) {
            <wui-placeholder>
                {{ 'apps.gateway.connectingPlaceholder' | translate }}
            </wui-placeholder>
        }

        <!-- Is connected -->
        @if (gatewayService.isConnected) {

            @let state = gatewayService.state;

            <!-- Has no state yet -->
            @if (state === undefined) {
                <wui-placeholder>
                    {{ 'apps.gateway.gettingStatePlaceholder' | translate }}
                </wui-placeholder>
            } @else {

                <!-- State -->
                <wui-section name="network">
                    <wui-entry>
                        <div name>Identifier</div>
                        <div value>{{ state.id }}</div>
                    </wui-entry>
                    <wui-entry>
                        <div name>Connector</div>
                        <div value>{{ gatewayService.connectorName }}</div>
                    </wui-entry>
                </wui-section>

                <wui-section name="state">
                    <wui-entry>
                        <div name>Software</div>
                        <div value
                            [class.pulsing]="state.isCompatible === false"
                            [class.invalid]="state.isCompatible === false"
                            (click)="openUpdates()"
                            wuiStopPropagation>
                            {{ state.software }}
                        </div>
                    </wui-entry>
                    <wui-entry>
                        <div name>Hardware</div>
                        <div value>{{ state.hardware || ('shared.unknown' | translate) }}</div>
                    </wui-entry>
                </wui-section>

                @let sd = state.sd;

                @if (sd) {
                    <wui-section name="sd">
                        <wui-entry>
                            <div name>Version</div>
                            <div value
                                [class.pulsing]="sd.isCompatible === false"
                                [class.invalid]="sd.isCompatible === false"
                                (click)="websiteService.openSoftwarePage(ProductsWithSoftware.HeadquarterGatewaySd)">
                                {{ sd.version }}
                            </div>
                        </wui-entry>
                        <wui-entry>
                            <div name>Language</div>
                            <div value>{{ sd.language }}</div>
                        </wui-entry>
                        <wui-entry>
                            <div name>Description</div>
                            <div value>{{ sd.description }}</div>
                        </wui-entry>
                    </wui-section>
                }

                <wui-section name="development" developpersOnly>
                    <wui-entry>
                        <div name>LoraMesh</div>
                        <wui-button value (click)="gatewayService.send(GatewayCommandType.ResetLoRa)">
                            {{ 'shared.reset' | translate }}
                        </wui-button>
                    </wui-entry>
                    <wui-entry>
                        <div name>Raw data</div>
                        <wui-json value [value]="state"></wui-json>
                    </wui-entry>
                </wui-section>
            }
        }
    </app-body>
    <app-footer>
        @if (gatewayService.isConnected) {
            <wui-buttons>
                <wui-button (click)="gatewayService.disconnect()">
                    {{ 'shared.disconnect' | translate }}
                </wui-button>
            </wui-buttons>
        } @else {
            <wui-buttons>
            @if (gatewayService.isStarted) {
                <wui-button (click)="gatewayService.disconnect()">
                    {{ 'shared.cancel' | translate }}
                </wui-button>
            } @else {
                <wui-button (click)="gatewayService.connect()">
                    {{ 'shared.connect' | translate }}
                </wui-button>
            }
            </wui-buttons>
        }
    </app-footer>
</app-container>
