<app-container>

    <app-header [logo]="'assets/apps/operations/logo.svg'"
                [description]="operationsService.operation ?
                    (operationsService.operation.name | titlecase) + ': ' + operationsService.operation.description :
                    'apps.operations.headerDescription' | translate | capitalize">
    </app-header>

    <app-disclamer
        *ngIf="!gatewayService.isConnected"
        level="error"
        (click)="environmentService.windowOpenByAppId('gateway'); $event.stopPropagation()"
        [isInteractive]="true">
        {{ 'apps.operations.disclamers.gatewayRequired' | translate }}
     </app-disclamer>

    <ng-container *ngIf="operationsService.operation === undefined">
        <app-body>
            <wui-cards class="cards">
                <wui-card   *ngFor="let operation of operationsService.operations"
                            [tag]="operation.tag"
                            [title]="operation.name"
                            [description]="operation.description"
                            [logo]="operation.logo"

                            [wuiDisabled]="operation.disabled === true"
                            (click)="operationsService.select(operation)">
                </wui-card>
            </wui-cards>
        </app-body>
    </ng-container>

    <ng-container *ngIf="operationsService.operation">
        <ng-container *ngComponentOutlet="operationsService.operation.component"></ng-container>
    </ng-container>

</app-container>
