import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { App, Window, EnvironmentService } from '@lightning/wild-environment';
import { RegisterService } from '../../../shared/services/register/register.service';
import { ElectronService } from '../../../shared/services/electron/electron.service';

import { ModalListComponent } from '../../../shared/components/modal-list/modal-list.component';
import { ModalTeamAssignComponent } from '../../../shared/components/modal-team-assign/modal-team-assign.component';
import { ModalAlertComponent } from '../../../shared/components/modal-alert/modal-alert.component';
import { ModalCardsComponent } from '../../../shared/components/modal-cards/modal-cards.component';
import { ModalInputComponent } from '../../../shared/components/modal-input/modal-input.component';
import { ModalTextareaComponent } from '../../../shared/components/modal-textarea/modal-textarea.component';
import { ModalYesNoComponent } from '../../../shared/components/modal-yes-no/modal-yes-no.component';

@Component({
    selector: 'app-sandbox',
    templateUrl: './sandbox.component.html',
    styleUrls: ['./sandbox.component.scss']
})

export class SandboxComponent implements App {

    @Input()
    public window: Window | undefined;

    private notificationIndex = 0;

    constructor(public environmentService: EnvironmentService,
                public ipcService: ElectronService,
                public registerService: RegisterService) {}

    public async modalAlert(): Promise<void> {

        await this.environmentService.modalOpen({
            title: 'modal alert',
            component: ModalAlertComponent,
            inputs: {
                description: 'description',
                logo: 'assets/apps/operations/logo.svg'
            }
        });

        this.environmentService.notificationOpen({ message: 'read' });

    }

    public async modalCards(): Promise<void> {

        const result = await this.environmentService.modalOpen({
            title: 'modal cards',
            component: ModalCardsComponent,
            inputs: {
                description: 'description',
                logo: 'assets/apps/operations/logo.svg',
                items: [
                    {
                        title: 'change drop point',
                        logo: 'assets/apps/operations/drops/logo.svg',
                        description: 'for a rondom other one',
                        data: {}
                    },
                    {
                        title: 'double points',
                        logo: 'assets/apps/operations/bonus/double.svg',
                        description: 'for 5 minutes',
                        data: {}
                    }
                ]
            }
        });

        if (result) {

            this.environmentService.notificationOpen({ message: JSON.stringify(result) });
        }
    }

    public async modalInput(): Promise<void> {

        const result = await this.environmentService.modalOpen({
            title: 'modal input',
            component: ModalInputComponent,
            inputs: {
                description: 'description',
                logo: 'assets/apps/operations/logo.svg',
                placeholder: '...'
            }
        });

        if (result) {

            this.environmentService.notificationOpen({ message: result });
        }
    }

    public async modalTextarea(): Promise<void> {

        const result = await this.environmentService.modalOpen({
            title: 'modal textarea',
            component: ModalTextareaComponent,
            inputs: {
                description: 'description',
                logo: 'assets/apps/operations/logo.svg',
                placeholder: '...'
            }
        });

        if (result) {

            this.environmentService.notificationOpen({ message: result });
        }
    }

    public async modalList(itemCount = 50): Promise<void> {

        const items = [];

        for(let i = 0; i < itemCount; i++) {
            items.push({label: 'item ' + i});
        }

        const result = await this.environmentService.modalOpen({
            title: 'modal list',
            component: ModalListComponent,
            inputs: {
                description: 'description',
                logo: 'assets/apps/operations/logo.svg',
                items
            }
        });

        if (result) {

            this.environmentService.notificationOpen({ message: JSON.stringify(result) });
        }
    }

    public async modalTeamAssign(): Promise<void> {

        if (this.registerService.operators.length === 0) {
            return;
        }

        const operator = this.registerService.operators[0];

        await this.environmentService.modalOpen({
            title: 'assign to a team',
            component: ModalTeamAssignComponent,
            inputs: {
                logo: 'assets/apps/operations/logo.svg',
                description: 'An operator not assigned to a team made an action, please assign',
                operator
            }
        });

        if (!operator.teamId) {
            return;
        }

        this.environmentService.notificationOpen({ message: operator.teamId });
    }

    public async modalYesNo(): Promise<void> {

        const result = await this.environmentService.modalOpen({
            title: 'modal input',
            component: ModalYesNoComponent,
            inputs: {
                description: 'description',
                logo: 'assets/apps/operations/logo.svg'
            }
        });

        if (result !== undefined) {

            this.environmentService.notificationOpen({ message: result });
        }
    }

    public notificationOpen(): void {

        this.environmentService.notificationOpen({ message: 'Say hello to my little friend ' + (this.notificationIndex++) });

    }


}
