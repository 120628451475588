import { Component, OnInit } from '@angular/core';

import { EnvironmentService } from '@lightning/wild-environment';
import { Tabs } from '@lightning/wild-ui';
import { GatewayService } from '../../../shared/services/gateway/gateway.service';

import { CheckingComponent } from '../checking/checking.component';
import { SandboxComponent } from '../sandbox/sandbox.component';
// import { ManualComponent } from '../manual/manual.component';
// import { FxComponent } from '../fx/fx.component';

@Component({
    selector: 'app-pmr',
    templateUrl: './pmr.component.html',
    styleUrls: ['./pmr.component.scss']
})

export class PmrComponent implements OnInit {

    public tabs: Tabs = {
        items: [
            { selector: 'checking', text: 'checking', component: CheckingComponent },
            { selector: 'sandbox', text: 'sandbox', component: SandboxComponent },
            // { selector: 'fx', text: 'fx', component: FxComponent },
            // { selector: 'manual', text: 'manual', component: ManualComponent },

            //... other manuals for other tabs ...
        ]
    };

    constructor(
        public environmentService: EnvironmentService,
        public gatewayService: GatewayService) { }

    ngOnInit() {

        this.tabs.selected = this.tabs.items[0];

        this.gatewayService.connect();
    }

}
