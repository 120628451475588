import { EventEmitter } from '@angular/core';

const PROCESS_INTERVAL = 100;

export enum TimerState {
    Iddle,
    Running,
    Paused,
    Complete
}

export enum TimerMode {
    Chronometer,
    Countdown
}

interface IntervalListener {
    id: number;
    interval: number;
    lastTick: number;
    callback: (time: number) => void;
}

export class Timer {

    private _mode: TimerMode = TimerMode.Countdown;
    private _state: TimerState = TimerState.Iddle;

    private _timer: any;
    private _duration = 0;
    private _timeOfRun = 0;
    private _timeOfPause = 0;
    private _intervalListeners: Array<IntervalListener> = [];

    public onSyncSecond: EventEmitter<number> = new EventEmitter<number>();
    public onSyncMinute: EventEmitter<number> = new EventEmitter<number>();
    public onComplete: EventEmitter<void> = new EventEmitter<void>();

    // Improvements:
    // - Events for timer state change
    // - Timer pause automaticaly if the app is pushed to the background (when setInterval is not effective)


    public get mode():TimerMode {

        return this._mode;
    }

    public get state(): TimerState {

        return this._state;
    }

    public get time(): number {

        if (this._mode === TimerMode.Chronometer) {

            return this._timeOfRun;
        }

        return this._duration - this._timeOfRun;
    }

    public setCountDown(duration: number) {
        this._duration = duration;
    }

    public startCountdown(duration: number = 0): void {

        this.clear();

        if (duration) {
            this._duration = duration;
        }

        this._mode = TimerMode.Countdown;

        this.run();
    }

    public startChronometer(): void {

        this.clear();

        this._duration = 0;

        this._mode = TimerMode.Chronometer;

        this.run();
    }

    public pause(): void {

        this._timeOfPause = 0;

        this._state = TimerState.Paused;
    }

    public resume(): number {

        this._state = TimerState.Running;

        return this._timeOfPause;
    }

    public complete(): void {

        this._state = TimerState.Complete;

        this.onComplete.emit();

        this.clear();
    }

    public clear(): void {

        if (this._timer) {
            clearInterval(this._timer);
        }

        this._duration = this._timeOfRun = this._timeOfPause = 0;

        this._state = TimerState.Iddle;
    }


    public setInterval(callback: (time: number) => void, interval: number): number {

        const id = Date.now();

        this._intervalListeners.push({
            id,
            interval,
            lastTick: Date.now(),
            callback
        });

        return id;
    }

    public clearInterval(id: number): void {
        this._intervalListeners = this._intervalListeners.filter(listener => listener.id != id);
    }


    private run(): void {

        this._timer = setInterval(() => this.process(), PROCESS_INTERVAL);

        this._state = TimerState.Running;

    }

    private process(): void {

        switch(this._state) {

            // The timer is runing
            case TimerState.Running:

                this._timeOfRun += PROCESS_INTERVAL;

                // Process listeners
                for (const listener of this._intervalListeners) {

                    if (Math.abs(this.time - listener.lastTick) >= listener.interval) {

                        listener.callback(this.time);

                        listener.lastTick = this.time;
                    }
                }

                // Sync entire second
                if (this._timeOfRun % 1000 === 0) {

                    const second = (this._mode === TimerMode.Countdown ? this._duration - this._timeOfRun : this._timeOfRun) / 1000;

                    this.onSyncSecond.emit(second);
                }

                // Sync entire minute
                if (this._timeOfRun % 60000 === 0) {

                    const minute = (this._mode === TimerMode.Countdown ? this._duration - this._timeOfRun : this._timeOfRun) / 60000;

                    this.onSyncMinute.emit(minute);
                }

                // The timer is complete
                if (this.mode === TimerMode.Countdown && this._timeOfRun >= this._duration) {

                    this.complete();
                }

            break;

            // The timer is paused
            case TimerState.Paused:

                this._timeOfPause += PROCESS_INTERVAL;

            break;

        }
    }

}
