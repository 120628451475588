<app-body>

    <wui-section [name]="'state'">

        <wui-entry>
            <div name>Raw state</div>
            <wui-json [value]="pmrService.state"></wui-json>
        </wui-entry>

    </wui-section>

    <wui-section [name]="'tx'">

        <wui-entry>
            <div name>Channel</div>
            <wui-select value [(value)]="txChannel">
                <option *ngFor="let team of registerService.teams" [value]="team.pmr">{{ team.name }} team ({{ team.pmr }})</option>
                <option [value]="pmrService.broadcastChannel">broadcast {{ pmrService.broadcastChannel }}</option>
            </wui-select>
        </wui-entry>

        <wui-entry>
            <div name></div>
            <wui-button value (click)="applyTxChannel()" [wuiDisabled]="pmrService.state.isPlaying">apply</wui-button>
        </wui-entry>

        <wui-entry>
            <div name>Track</div>
            <wui-select value [(value)]="track">
                <option *ngFor="let item of sounds | keyValue" [value]="item.value">{{ item.key }}</option>
            </wui-select>
        </wui-entry>

        <wui-entry>
            <div name></div>
            <wui-button value (click)="play()">play</wui-button>
        </wui-entry>

        <wui-entry>
            <div name></div>
            <wui-button value (click)="stop()">stop</wui-button>
        </wui-entry>

        <wui-entry>
            <div name></div>
            <wui-button value (click)="loopPlay()">{{ isLoopPlaying ? 'stop' : 'start' }} loop play</wui-button>
        </wui-entry>

        <wui-entry>
            <div name></div>
            <wui-button value (wuiPointerStart)="startTransmission()" (wuiPointerEnd)="endTransmission()">ptt</wui-button>
        </wui-entry>

    </wui-section>

    <wui-section [name]="'rx'">

        <wui-entry>
            <div name>Channel</div>
            <wui-select value [(value)]="rxChannel">
                <option *ngFor="let team of registerService.teams" [value]="team.pmr">{{team.name}} team ({{ team.pmr }})</option>
                <option [value]="pmrService.broadcastChannel">broadcast {{ pmrService.broadcastChannel }}</option>
            </wui-select>
        </wui-entry>

        <wui-entry>
            <div name></div>
            <wui-button value (click)="applyRxChannel()">apply</wui-button>
        </wui-entry>

    </wui-section>


</app-body>
