import { EnvironmentService } from '@lightning/wild-environment';
import { Injectable } from '@angular/core';

import { StyleUtils } from '@lightning/wild-ui';
import { SettingsService, Settings } from '../settings/settings.service';
import { GatewayConnectorsNames } from '../../enums/gateway.enum';
import { GatewayConnector } from './connectors/connector.interface';

import { WebSerialGatewayConnector } from './connectors/web-serial.connector';
import { WebUsbGatewayConnector } from './connectors/web-usb.connector';
import { WebSocketGatewayConnector } from './connectors/web-socket.connector';
import { WebBluetoothGatewayConnector } from './connectors/web-bluetooth.connector';
import { SimulationGatewayConnector } from './connectors/dummy.connector';
import { Subscription } from 'rxjs';
import { MinimalCompatibleVersions, SementicVersionUtils } from '@lightning/lightning-definitions';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@lightning/headquarter/environments/environment';

export enum GatewayCommandType {
    RequestState =          'state?',
    RequestWifiNetworks =   'wifiNetworks?',
    SendLoRa =              'lora',
    ResetLoRa =             'resetLora',
    SendPMR =               'pmr',
    Update =                'update'
}


export class GatewayStateSd {

    public version = '';

    public isCompatible = false;

    public language = '';

    public description = '';
}

export class GatewayState {

    public id = '';

    public hardware = '';

    public software = '';

    public isCompatible = false;

    public sd = new GatewayStateSd();
}



@Injectable({
    providedIn: 'root'
})

export class GatewayService {

    private listeners: Array<any> = new Array<any>();

    private _connector: GatewayConnector;

    private _isStarted = false;

    private _isReconnecting = false;

    private _state: GatewayState | undefined;

    private _stateTimer: any = null;

    private _subscriptions: Array<Subscription> = [];

    private _wifiNetworks: Array<string> = [];

    constructor(
        private environmentService: EnvironmentService,
        private settingsService: SettingsService,
        private translateService: TranslateService) {

        this._connector = new SimulationGatewayConnector();

        this.selectConnector(settingsService.settings.gateway.connector);

        this.settingsService.onSaved.subscribe({
            next: (settings: Settings) => {
                if (settings.gateway.connector !== this.connectorName) {
                    this.selectConnector(settings.gateway.connector);
                }
            }
        });
    }


    public get connectorName(): GatewayConnectorsNames {
        return this._connector.name;
    }

    public get state(): GatewayState | undefined {
        return this._state;
    }

    public get wifiNetworks(): Array<string> {
        return this._wifiNetworks;
    }

    private unselectConnector() {

        if (!this._connector) {
            return;
        }

        this._isStarted = false;

        this._connector.disconnect();

        for (const subscription of this._subscriptions) {
            subscription.unsubscribe();
        }

        this._subscriptions = [];
    }

    public selectConnector(name: GatewayConnectorsNames): void {

        this.unselectConnector();

        switch(name) {

            case GatewayConnectorsNames.Simulation:
                this._connector = new SimulationGatewayConnector();
            break;

            case GatewayConnectorsNames.Serial:
                this._connector = new WebSerialGatewayConnector();
            break;

            case GatewayConnectorsNames.Usb:
                this._connector = new WebUsbGatewayConnector();
            break;

            case GatewayConnectorsNames.Bluetooth:
                this._connector = new WebBluetoothGatewayConnector();
            break;

            case GatewayConnectorsNames.Websocket:
                this._connector = new WebSocketGatewayConnector(this.settingsService.settings.gateway.websocketUrl);
            break;
        }

        // On connect
        this._subscriptions.push(this._connector.onConnect
            .subscribe(() => {

                this.environmentService.notificationOpen({
                    message: this.translateService.instant('services.gateway.connected', { connectorName: this.connectorName }),
                    callback: () => {
                        this.environmentService.windowOpenByAppId('gateway');
                    }
                });

                this.requestState();

            }));

        // On connect fail
        this._subscriptions.push(this._connector.onConnectFail
            .subscribe(() => {
                this.environmentService.notificationOpen({
                    message: this.translateService.instant('services.gateway.connectionFailed', { connectorName: this.connectorName }),
                    color: StyleUtils.getVariable('--color-error'),
                    callback: () => {
                        this.environmentService.windowOpenByAppId('gateway');
                    }
                });

                this.reconnect();
            }));

        // On disconnect
        this._subscriptions.push(this._connector.onDisconnect
            .subscribe(() => {

                if (this.connectorName !== GatewayConnectorsNames.Simulation) {
                    this.environmentService.notificationOpen({
                        message: this.translateService.instant('services.gateway.disconnected', { connectorName: this.connectorName }),
                        color: StyleUtils.getVariable('--color-error'),
                        callback: () => {
                            this.environmentService.windowOpenByAppId('gateway');
                        }
                    });
                }

                this.clearState();

                this.reconnect();
            }));

        // On receive
        this._subscriptions.push(this._connector.onReceive
            .subscribe((data) => {

                // Filtering useful lines
                if (!data.match('^(lora|pmr|state|wifiNetworks).*')) {

                    console.warn(`GatewayService: Receiving logs: ${data}`);

                    return;
                }

                console.log(`GatewayService: Receiving data: ${data}`);

                try {

                    const type = data.substring(0, data.indexOf(' '));
                    const content = JSON.parse(data.substring(data.indexOf(' ') + 1));

                    if (type === 'state') {

                        this.storeState(content);

                        return;
                    }

                    if (type === 'wifiNetworks') {

                        this._wifiNetworks = [...new Set<string>(content.ssids)];   // Remove duplicate ssids

                        return;
                    }

                    this.fireListener({ type, content });

                } catch(exception) {

                    console.error('GatewayService: Unable to parse JSON data:', data);
                }

            }));
    }


    public get isStarted(): boolean {

        return this._isStarted;
    }

    public get isConnecting(): boolean {

        return this.isStarted && this.isConnected === false;
    }

    public get isConnected(): boolean {

        return this._connector.isConnected;
    }


    public send(command: GatewayCommandType, content = ''): number {

        const token = (new Date()).getTime();

        const data = command + ' ' + token + ' ' + content;

        console.log('GatewayService: Send', data);

        this._connector.send(data);

        return token;
    }

    public async connect(): Promise<void> {

        // To prevent ExpressionChangedAfterItHasBeenCheckedError
        await new Promise(resolve => setTimeout(resolve, 0));

        if (this.isStarted) {
            return;
        }

        this._connector.connect();

        this._isStarted = true;
    }

    public disconnect(): void {

        this._isStarted = false;

        this.clearState();

        if (this.isConnected) {
            this._connector.disconnect();
        }
    }


    public on(type: string, callback: any): void {
        this.listeners.push({ type, callback });
    }

    public removeListener(type: string): void {
        this.listeners = this.listeners
            .filter(listener => listener.type !== type);
    }

    private fireListener(data: any): void {

        const listerners = this.listeners
            .filter(listener => listener.type === data.type);

        for (const listerner of listerners) {
            listerner.callback(data.content);
        }
    }


    private reconnect(): void {

        // Is already reconnecting
        if(this._isReconnecting) {
            return;
        }

        // Is not able to be be reconnected
        if (!this.isAbleToReconnect) {
            return;
        }

        this._isReconnecting = true;

        setTimeout(() => {

            // The situation may changed after the delay
            if (this.isAbleToReconnect) {
                this._connector.connect();
            }

            this._isReconnecting = false;

        }, environment.gateway.reconnectionDelay);

    }

    private get isAbleToReconnect(): boolean {

        // Is not started
        if (!this.isStarted) {
            return false;
        }

        // Is already connected
        if (this.isConnected) {
            return false;
        }

        // Only some connectors are able to try to reconnect
        return this.connectorName === GatewayConnectorsNames.Websocket
            || this.connectorName === GatewayConnectorsNames.Serial
            || this.connectorName === GatewayConnectorsNames.Usb;
    }


    private requestState(): void {

        if (this._stateTimer) {
            return;
        }

        this._stateTimer =
            setInterval(this.send.bind(this, GatewayCommandType.RequestState), environment.gateway.getStateInterval);
    }

    private storeState(state: GatewayState): void {

        // Stop the timer
        this.clearState();

        // Store the state
        this._state = state;

        // Ignore checkings for the dummy connector
        if (this.connectorName === GatewayConnectorsNames.Simulation) {
            return;
        }

        // Check the compatibility of the software
        this._state.isCompatible =
            SementicVersionUtils.isCompatibleFromStrings(state.software, MinimalCompatibleVersions.HeadquarterGateway);

        if (!this._state.isCompatible) {
            this.environmentService.notificationOpen({
                message: this.translateService.instant('services.gateway.incompatibleSoftware'),
                color: StyleUtils.getVariable('--color-error'),
                callback: () => {
                    this.environmentService.windowOpenByAppId('updates');
                }
            });
        }

        // Check the presence of the sd
        if (!this._state.sd) {

            this.environmentService.notificationOpen({
                message: this.translateService.instant('services.gateway.missingSd'),
                color: StyleUtils.getVariable('--color-error'),
                callback: () => {
                    this.environmentService.windowOpenByAppId('gateway');
                }
            });

            return;
        }

        // Check the compatibility of the sd version
        this._state.sd.isCompatible =
            SementicVersionUtils.isCompatibleFromStrings(state.sd.version, MinimalCompatibleVersions.HeadquarterGatewaySd);

        if (!this._state.sd.isCompatible) {
            this.environmentService.notificationOpen({
                message: this.translateService.instant('services.gateway.incompatibleSd'),
                color: StyleUtils.getVariable('--color-error'),
                callback: () => {
                    this.environmentService.windowOpenByAppId('gateway');
                }
            });
        }

    }

    private clearState(): void {

        clearInterval(this._stateTimer);

        this._stateTimer = null;

        this._state = undefined;
    }

}
