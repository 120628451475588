import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { App, EnvironmentService, Window } from '@lightning/wild-environment';
import { FirmwaresSearchRequest, FirmwaresSearchResponse, LoraDevice, MinimalCompatibleVersions, ProductsWithSoftware, SementicVersionUtils } from '@lightning/lightning-definitions';
import { OnlineService } from '@lightning/lightning-services';

import { LoraProtocolService } from '../../../shared/services/lora-protocol/lora-protocol.service';
import { SettingsService } from '../../../shared/services/settings/settings.service';
import { WebsiteService } from '../../../shared/services/website/website.service';
import { GatewayCommandType, GatewayService } from '../../../shared/services/gateway/gateway.service';


@Component({
    selector: 'app-updates',
    templateUrl: './updates.component.html',
    styleUrls: ['./updates.component.scss']
})
export class UpdatesComponent implements App, OnInit, OnDestroy {

    @Input()
    public window: Window | undefined;

    public MinimalCompatibleVersions = MinimalCompatibleVersions;

    public ProductsWithSoftware = ProductsWithSoftware;

    public latestVersions = new Map<string, string>();

    private timerWifiScan: any = null;

    constructor(
        public settingsService: SettingsService,
        public loraProtocolService: LoraProtocolService,
        public gatewayService: GatewayService,
        private translateService: TranslateService,
        private environmentService: EnvironmentService,
        private onlineService: OnlineService,
        private websiteService: WebsiteService) {}

    public ngOnInit() {

        this.timerWifiScan = setInterval(() => {
            this.gatewayService.send(GatewayCommandType.RequestWifiNetworks);
        }, 5000);

        this.loadLatestVersions();
    }

    public ngOnDestroy() {

        clearInterval(this.timerWifiScan);
    }

    public get isWifiReacheable(): boolean {
        return this.gatewayService.wifiNetworks
            .indexOf(this.settingsService.settings.wifi.ssid) >= 0;
    }

    public isCompatibleGatewaySofware(): boolean {

        if (!this.gatewayService.state) {
            return true;
        }

        return this.gatewayService.state.isCompatible;
    }

    public isCompatibleLoraDeviceSofware(device: LoraDevice): boolean {

        if (device.state?.software === undefined) {
            return true;
        }

        return device.isCompatible;
    }


    public async updateGateway(): Promise<void> {

        const wifiCredentials = await this.settingsService.getOrRequestWifiCredentials();

        if (!wifiCredentials) {
            return;
        }

        const channel = this.settingsService.settings.advanced.updatesChannel;

        this.gatewayService.send(GatewayCommandType.Update, `${wifiCredentials.ssid} ${wifiCredentials.password} ${channel}`);

        this.environmentService.notificationOpen({
            message: this.translateService.instant('apps.updates.updating.gateway')
        });
    }

    public async updateLoraDevice(device: LoraDevice): Promise<void> {

        const wifiCredentials = await this.settingsService.getOrRequestWifiCredentials();

        if (!wifiCredentials) {
            return;
        }

        const channel = this.settingsService.settings.advanced.updatesChannel;

        this.loraProtocolService.sendUpdateCommand(wifiCredentials, channel, device.id);

        this.environmentService.notificationOpen({
            message: this.translateService.instant('apps.updates.updating.device', device)
        });
    }

    public async updateAll(): Promise<void> {
        /* */
    }

    public openReleaseNotes(): void {
        this.websiteService.openSoftwarePage();
    }

    public loadLatestVersions(): void {

        this.latestVersions.clear();

        Object.keys(ProductsWithSoftware).forEach((product: string) => {

            const firmwareRequest: FirmwaresSearchRequest = {
                index: 0,
                limit: 1,
                targetProduct: product as ProductsWithSoftware,
                channel: this.settingsService.settings.advanced.updatesChannel
                // targetHardwareVersion: SementicVersionUtils.toString({
                //     major: 2,
                //     minor: 0,
                //     patch: 0
                // })
            };

            this.onlineService.searchFirmwares(firmwareRequest)
                .subscribe({
                    next: (response: FirmwaresSearchResponse) => {

                        if (response.items.length === 0) {
                            return;
                        }

                        this.latestVersions.set(product, SementicVersionUtils.toString(response.items[0].version))

                        // console.log(this.latestVersions);
                    }
                });
        });

    }
}
